<script setup>
  import BackgroundNumberStyle from './BackgroundNumberStyle.vue';
  const uri = window.location
</script>

<template>
  <div class="hk-wholeapp-wrapper position-relative">

    <BackgroundNumberStyle numberType="404"/>

    <div class="hk-easter-egg-holder position-absolute">
      <div>
        <svg viewBox="0 0 24 24">
          <path :fill="robotColor" d="M10.62 14.44L9.56 15.5L10.62 16.56L9.56 17.62L8.5 16.56L7.44 17.62L6.38 16.56L7.44 15.5L6.38 14.44L7.44 13.38L8.5 14.44L9.56 13.38L10.62 14.44M16.56 13.38L15.5 14.44L14.44 13.38L13.38 14.44L14.44 15.5L13.38 16.56L14.44 17.62L15.5 16.56L16.56 17.62L17.62 16.56L16.56 15.5L17.62 14.44L16.56 13.38M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C17.87 7 21 10.13 21 14H22C22.55 14 23 14.45 23 15M21 16H19V14C19 11.24 16.76 9 14 9H10C7.24 9 5 11.24 5 14V16H3V17H5V20H19V17H21V16Z"></path>
        </svg>
      </div>
    </div>

    <div class="hk-wholeapp-container position-relative">
      <h2>{{ uri.host }}</h2>
    </div>

    <div class="hk-wholeapp-container position-relative">
      <h1 @click="changeColor" @dblclick="removeColor">This site is missing.</h1>
    </div>

    <div class="hk-wholeapp-container position-relative">
      <p>HKubo</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ModuleType404',
  data() {
    return {
      robotColor: 'transparent',
      clicks: 0,
    }
  },
  methods: {
    changeColor() {
      const chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F']
      let color = '#'
      color += chars[(Math.floor(Math.random() * chars.length))]
      color += chars[(Math.floor(Math.random() * chars.length))]
      color += chars[(Math.floor(Math.random() * chars.length))]
      this.robotColor = color
      this.clicks++
    },
    removeColor() {
      this.robotColor = 'transparent'
      this.clicks++
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';

.hk-easter-egg-holder {
  pointer-events: none;
  position: absolute;
  height: 100px;
  width: 100%;
  top: -23vh;

  > * {
    display: flex;
    justify-content: center;
  }

  svg {
    width: 100px;
    height: 100px;
    path {
      transition: 1s all;
    }
  }
}
.hk-wholeapp-container {
  > * {
    display: flex;
    justify-content: center;
  }

  h2 {
    color: $accent_main;
    margin: 0;
  }
  h1 {
    font-family: Syne;
    margin: 2rem 0;
    text-align: center;
    font-weight: 900;
  }
  @media screen and (max-width: 449.99px){
    h1 {
      font-size: 35px;
      line-height: 2.1rem;
    }
  }
  @media screen and (min-width: 450px){
    h1 {
      font-size: 50px;
      line-height: 3rem;
    }
  }
  p {
    color: $text_label;
  }
  img {
    width: 100px;
  }
}
</style>
