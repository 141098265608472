<script setup>
  import ModuleType404 from '@/components/ModuleType404.vue'
  import ModuleType502 from '@/components/ModuleType502.vue'
  import CookiesOverlay from './components/CookiesOverlay.vue';
  const moduleCode = process.env.VUE_APP_ERROR_TYPE
</script>

<template>
  <main class="hk-wholeapp-wrapper">

    <div v-if="moduleCode === '502'">
      <ModuleType502/>
    </div>
    
    <div v-else>
      <ModuleType404/>
    </div>

  </main>

  <CookiesOverlay v-if="showCookiePolicy"/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      robotColor: 'transparent',
      clicks: 0,
    }
  },
  computed: {
    showCookiePolicy() {
      return process.env.VUE_APP_SHOW_COOKIES ? process.env.VUE_APP_SHOW_COOKIES === 'true' : false
    },
  },
  methods: {
    changeColor() {
      const chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F']
      let color = '#'
      color += chars[(Math.floor(Math.random() * chars.length))]
      color += chars[(Math.floor(Math.random() * chars.length))]
      color += chars[(Math.floor(Math.random() * chars.length))]
      this.robotColor = color
      this.clicks++
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

body, html {
  margin: 0;
  background-color: $background_main;
}
* {
  font-family: Manrope;
  color: $text_main;
}
main {
  position: relative;
  min-height: 100vh;
  background-color: $background_main;
  background: linear-gradient(0deg, #040202, $background_main);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

</style>
