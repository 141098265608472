<template>
  <div class="position-absolute hk-bg-num-holder w-100 d-flex justify-content-center align-items-center">
    <div class="hk-bg-num-num">{{ numberType }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      numberType: {
        typeof: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/bootstrap';

.hk-bg-num-holder {
  //background-color: rgba(thistle, 0.1);
  overflow: hidden;
  z-index: 0;
  pointer-events: none;

  .hk-bg-num-num {
    font-family: Syne;
    font-weight: 900;
    color: rgba($black, 0.2);
  }

}

@media screen and (max-width: 449.99px){
  .hk-bg-num-holder {
    top: -170px;
  }
  .hk-bg-num-num {
    font-size: 270px;
    line-height: 270px;
  }
}
@media screen and (min-width: 450px){
  .hk-bg-num-holder {
    top: -250px;
  }
  .hk-bg-num-num {
    font-size: 400px;
    line-height: 400px;
  }
}

</style>
